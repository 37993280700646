import React from 'react';
import { Link } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import Layout from '../../components/Layout';
import { FaList, FaEnvelope, FaTwitter } from "react-icons/fa"

/**
 * アバウトコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const About: React.FC = () => {
  return (
    <HelmetProvider>
      <Head pageName='当サービスについて' />
      <Layout>
        <h2 className="h4">
          <FaList className='me-2 pb-2' />
          当サービスについて
        </h2>
        <div>開発者は、特許（商標）事務に従事しており、識別番号情報を検索できたら便利だなと思い、開発しました。</div>
        <div>当サービスでは、特許庁が発行している公報に掲載されている識別番号に係る出願人情報を掲載しています。</div>
        <div>そのため、識別番号に係る情報は最新であるとは限りませんので、参考程度にご覧ください。</div>
        <div>なお、当サービスについてのお問い合わせは<Link to="/contact">お問合せフォーム</Link>からお願いします。</div>
        <div className='mt-3'>
          <div>
            <FaEnvelope className='text-secondary me-1' />お問合せフォーム: <Link to="/contact">こちら</Link>
          </div>
          <div>
            <FaTwitter className='text-secondary me-1' />X（旧Twitter）: <a href='https://x.com/patentapplicant' target='_blank'>@patentapplicant</a>
          </div>
        </div>
      </Layout>
    </HelmetProvider>
  );
}

export default About;