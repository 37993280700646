import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import axios from 'axios';
import { IconContext } from 'react-icons';
import { FaSpinner } from 'react-icons/fa';
import Layout from '../../components/Layout';
import LatestInformation from '../../components/LatestInformation';
import ChangeHistories from '../../components/ChangeHistories';
import { FaUser } from 'react-icons/fa';
import { LatestApplicantInformation, ChangeHistoryApplicant } from '../../types';
import { convertKeysToCamelCase } from '../../common';

const uri = new URL(window.location.href);
axios.defaults.baseURL = uri.origin;

/**
 * 詳細コンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Detail: React.FC = () => {

  const { id } = useParams();
  const [applicant, setApplicant] = useState<LatestApplicantInformation>({
    identificationNumber: '',
    name: '',
    originalName: '',
    address: '',
    originalAddress: '',
    lastUpdatedAt: ''
  });
  const [applicantsList, setApplicantsList] = useState<ChangeHistoryApplicant[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  (useEffect(() => {
    axios.get(`./cgi-bin/get_applicant.py?identification_number=${id}`).then((response) => {
      const data = convertKeysToCamelCase(response.data);
      setApplicant({
        identificationNumber: data.applicant[0]['identificationNumber'],
        name: data.applicant[0]['name'],
        originalName: data.applicant[0]['originalName'],
        address: data.applicant[0]['address'],
        originalAddress: data.applicant[0]['originalAddress'],
        lastUpdatedAt: data.applicant[0]['effectiveEndDate']
      });
      setApplicantsList(data.applicant);
      setIsLoading(false);
    });
  }, []));

  return (
    <HelmetProvider>
      <Head pageName={String(id)} />
      <Layout>
        <h2 className="h4">
          <FaUser className='me-2 pb-2' />
          識別番号：{id}
        </h2>
        {
          isLoading
          ?
            <div className="text-center my-3">
              <IconContext.Provider value={{color: 'gray', size: '2rem' }}>
                <FaSpinner className="icon-spin" />
              </IconContext.Provider>
            </div>
          :
            applicant.identificationNumber === '' && applicant.name === '' && applicant.originalName === '' && applicant.address == '' && applicant.originalAddress === '' && applicant.lastUpdatedAt === '' && applicantsList.length === 0
            ?
              <div className="text-center">識別番号 id{id}に登録された情報が存在しません。</div>
            :
              <>
                <div className="mb-5">
                  <LatestInformation
                    applicant={applicant}
                  />
                </div>
                <div>
                  <ChangeHistories applicantsList={applicantsList} />
                </div>
              </>
        }
      </Layout>
    </HelmetProvider>
  );
}

export default Detail;
