import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * フッターコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Footer: React.FC = () => {
  return (
    <footer className="border-top text-center text-secondary small bg-light py-3">
      <Container>
        <p>Copyright (C) 特許出願人検索.net Rights Reserved.</p>
      </Container>
    </footer>
  );
}

export default Footer;