import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

/**
 * コンタクトコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Contact: React.FC = () => {
  return (
    <HelmetProvider>
    <Head pageName='お問い合わせ' />
      <Header />
      <main>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdJOd_JmhBTkdH58Pgasi0lDW29lUy1CW5Of8Jgy_1JazEMdg/viewform" title="google-forms" className="d-block" width="100%" height="800">読み込んでいます…</iframe>
      </main>
      <Footer />
    </HelmetProvider>
  );
}

export default Contact;