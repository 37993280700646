import React, { useState, ChangeEvent, FocusEvent } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { formatIdentificationNumber, toFullWidth } from '../../common';

/**
 * FormValue用のインターフェースです。
 * @interface FormValue
 */
interface FormValue {
  /**
   * 氏名又は名称
   * @type {string}
   * @memberof FormValue
   */
  name: string;

  /**
   * 識別番号
   * @type {string}
   * @memberof FormValue
   */
  identificationNumber: string;
}

/**
 * Searchコンポーネント用のPropsです。
 * @interface SearchProps
 */
interface SearchProps {

  /**
   * フォーム値です。
   * @type {FormValue}
   * @memberof SearchProps
   */
  formValue: FormValue;

  /**
   * form値を設定する関数です。
   * @type {FormValue}
   * @memberof SearchProps
   */
  onChange: (formValue: FormValue) => void;

  /**
   * 検索する関数です。
   * @type {FormValue}
   * @memberof SearchProps
   */
  onSearch: () => void;

}

/**
 * 検索コンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Search: React.FC<SearchProps> = ({ formValue, onChange, onSearch }) => {

  const [inputType, setInputType] = useState<string>((formValue.name === '' && formValue.identificationNumber === '') || formValue.identificationNumber !== '' ? '識別番号' : '氏名又は名称');
  // useEffect(() => {
  //   if(formValue.name !== '' || formValue.identificationNumber !== '') {
  //     onSearch();
  //   }
  // }, []);

  const handleInputType = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setInputType(value);
  }

  const handleNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    onChange({
      name: toFullWidth(value),
      identificationNumber: ''
    });
  };


  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    onChange({
      name: value,
      identificationNumber: ''
    });
  };

  const handleIdentificationNumberBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    onChange({
      name: '',
      identificationNumber: value !== '' ? formatIdentificationNumber(value) : ''
    });
  };

  const handleIdentificationNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    onChange({
      name: '',
      identificationNumber: value
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // フォームのデフォルトの送信を防ぐ
    // フォームの送信処理を実行するか、他の処理を行う
    onSearch();
  };

  return (
    <Container>
      {/* <div className="mb-3 rounded-3 align-baseline" style={{backgroundColor: '#ffe4b5'}}>aaaa</div> */}
      <h2 className="h4">
        <FaSearch className='me-2 pb-2' />
        出願人を検索
      </h2>
      <form onSubmit={handleSubmit} className="px-md-5">
        <div className="mb-3">
          <Form.Check
            inline
            label="識別番号"
            value="識別番号"
            name="inputType"
            type="radio"
            id="inputTypeIdentificationNumber"
            checked={inputType === '識別番号' ? true : false}
            onChange={handleInputType}
          />
          <Form.Check
            inline
            label="氏名又は名称"
            value="氏名又は名称"
            name="inputType"
            type="radio"
            id="inputTypeName"
            checked={inputType === '氏名又は名称' ? true : false}
            onChange={handleInputType}
          />
        </div>
        {
          inputType === '氏名又は名称' ?
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control type="text" value={formValue.name} placeholder="◯◯◯◯株式会社（個人名を検索する場合は「姓」と「名」の間にスペースを入れてください。）" onChange={handleNameChange} onBlur={handleNameBlur} />
            </Form.Group>
            :
            <Form.Group className="mb-3" controlId="formIdentification">
              <Form.Control type="text" value={formValue.identificationNumber} placeholder="123456789" onChange={handleIdentificationNumberChange} onBlur={handleIdentificationNumberBlur} />
            </Form.Group>
        }
        <div className="text-center">
          <Button type="submit" className="w-100" variant="primary" onClick={onSearch} disabled={formValue.name === '' && formValue.identificationNumber === '' ? true : false}>
            <FaSearch className='me-2 pb-1' />
            検索
          </Button>
        </div>
      </form>
    </Container>
  );
}

export default Search;