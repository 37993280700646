import React from 'react';
import { LatestApplicantInformation } from '../../types';
import { formatDateString } from '../../common';

/**
 * LatestInformationコンポーネント用のPropsです。
 * @interface LatestInformationProps
 */
interface LatestInformationProps {

  /**
   * 最新の出願人情報
   * @type {object}
   * @memberof LatestInformationProps
   */
  applicant: LatestApplicantInformation;

}


/**
 * 最新情報コンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const LatestInformation: React.FC<LatestInformationProps> = ({applicant}) => {

  return (
    <>
      <div className="mb-3 rounded-3 align-baseline" style={{backgroundColor: '#ffe4b5'}}>
        <h3 className="h5 py-2 px-2 fw-bold">最新情報</h3>
      </div>
      <div className="row mb-3 px-3">
        <h4 className="h10 col-md-3 col-12 text-secondary">識別番号</h4>
        <div className="h4 col-md-9 col-12 ps-md-0 ps-5">{applicant.identificationNumber}</div>
      </div>
      <div className="row mb-3 px-3">
        <h4 className="h10 col-md-3 col-12 text-secondary">氏名又は名称</h4>
        <div className="h4 col-md-9 col-12 ps-md-0 ps-5">
          <div className="my-1">{applicant.name}</div>
          {
            applicant.originalName ? <div className="my-1">{applicant.originalName}</div> : <></>
          }
        </div>
      </div>
      <div className="row mb-3 px-3">
        <h4 className="h10 col-md-3 col-12 text-secondary">住所又は居所</h4>
        <div className="h4 col-md-9 col-12 ps-md-0 ps-5">
          <div className="my-1">{applicant.address}</div>
          {
            applicant.originalName ? <div className="my-1">{applicant.originalAddress}</div> : <></>
          }
        </div>
      </div>
      <div className="row mb-3 px-3">
        <h4 className="h10 col-md-3 col-12 text-secondary">最終蓄積年月日</h4>
        <div className="h4 col-md-9 col-12 ps-md-0 ps-5">{formatDateString(applicant.lastUpdatedAt)}</div>
      </div>
    </>
  );
}

export default LatestInformation;
