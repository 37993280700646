import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import { Card, Table } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { IconContext } from 'react-icons'
import { FaFileDownload, FaFileCsv } from 'react-icons/fa'; 

/**
 * ダウンロードコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Download: React.FC = () => {

  const csvDefinitions: string[][] = [
    ['identification_number', '識別番号', 'integer', '9'],
    ['name', '氏名又は名称', 'text', '-'],
    ['address', '住所又は居所', 'text', '-'],
    ['origin_name', '氏名又は名称（原語表記）', 'text', '-'],
    ['origin_address', '住所又は居所（原語表記）', 'text', '-'],
    ['base_date', '基準日', 'text', '10（YYYY-MM-DD）']
  ];

  return (
    <HelmetProvider>
      <Head pageName='CSVダウンロード' />
      <Layout>
        <h2 className="h4">
          <FaFileDownload className='me-2 pb-2' />
          CSVダウンロード
        </h2>
        <div className="my-3">
          ダウンロード時点で発行されている公報情報（2022.1.1〜）から抽出した出願人の最新情報をダウンロードすることができます。
          <div className="row">
            <div className="col-xl-3 col-sm-4 col-12">
              <Card className="my-4">
                <Card.Header>
                  <h3 className="h5">最新の出願人情報</h3>
                </Card.Header>
                <Card.Body className='text-center'>
                  {/* <Card.Title>Card Title</Card.Title> */}
                  <Card.Text>
                  <a href='./cgi-bin/db/patent-applicant-search.zip' download="patent-applicant-search.zip" target='_blank'>
                    <IconContext.Provider value={{ color: '#ccc', size: '100px' }}>
                      <FaFileCsv />
                    </IconContext.Provider>
                  </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          
          <div className="mb-3 rounded-3 align-baseline" style={{backgroundColor: '#ffe4b5'}}>
            <h3 className="h5 py-2 px-2 fw-bold">CSVファイルの仕様について</h3>
          </div>
          <div>
            <div>
              <span className="me-3 text-bold">文字コード：</span>UTF-8
            </div>
            <div>
              <span className="me-3 text-bold">CSVコンテンツ：</span>
              <Table bordered hover>
                <thead className='table-secondary'>
                  <tr>
                    <th style={{width: '10%'}}>No.</th>
                    <th style={{width: '25%'}}>列名</th>
                    <th style={{width: '25%'}}>列名（日本語）</th>
                    <th style={{width: '20%'}}>型</th>
                    <th style={{width: '20%'}}>桁数</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    csvDefinitions.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}列目</td>
                          <td>{item[0]}</td>
                          <td>{item[1]}</td>
                          <td>{item[2]}</td>
                          <td>{item[3]}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Layout>
    </HelmetProvider>
  );
}

export default Download;