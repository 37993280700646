import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import topImage from '../../assets/images/top-image.png';

/**
 * レイアウトコンポーネント用propsです。
 * @interface LayoutProps
 */
interface LayoutProps {

  /**
   * children
   * @type {ReactNode}
   */
  children: ReactNode;

};

/**
 * レイアウトコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();


  return (
    <>
      <Header />
      <main>
      {
        location.pathname === '/'
        ?
        <div className="bg-light border-bottom">
          <Container className="pb-3">
            <div style={
                {
                  backgroundImage: `url(${topImage})`,
                  width: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  objectFit: 'cover',
                  color:'black',
                  borderRadius: '0 0 30px 30px'
                }
              }>
                <div style={
                  {
                    background: 'rgba(255, 255, 255, 0.17)',
                    display: 'flex',
                    justifyContent:'center',
                    alignItems: 'center'
                    }
                  }>
                  <div style={
                    {
                      paddingTop: 'calc(350 / 1000 * 50%)',
                      paddingBottom: 'calc(350 / 1000 * 50%)'
                    }
                  } />
                  <div className="text-center py-2" style={
                    {
                      height: '100%',
                      width:'100%',
                      background: 'rgba(255,255,255,0.5)'
                    }
                  }>
                    <span className="h4 fw-bold text-dark" style={{textShadow: '1px 1px 0 white,-1px 1px 0 white,1px -1px 0 white,-1px -1px 0 white'}}>
                      <div className="mt-md-2">特許庁の出願人情報（識別番号に届出の情報）を検索できるサービスです。</div>
                      <div className="d-md-block d-none mt-md-3">「この識別番号の出願人の以前登録されていた住所はなんだろう？」</div>
                      <div className="d-md-block d-none">「出願人が在外者の場合は住所をどのように登録すればよいか？」</div>
                      <div className="d-md-block d-none">という時に役立つ、特許事務所の事務員の方向けの検索サイトです！！</div>
                      <div className="d-md-block d-none mt-md-4 mb-md-2 small">
                        <span className="me-3">※</span>2022年1月以降に特許庁から発行された公報から出願人情報を蓄積しています</div>
                    </span>
                  </div>
                </div>
              </div>
          </Container>
        </div>
        :
        <></>
      }
        <Container className="my-md-5 my-3">
          {children}
        </Container>
      </main>
      <Footer />
    </>
  );
}

export default Layout;