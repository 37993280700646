import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

/**
 * Resultコンポーネント用のPropsです。
 * @interface ResultProps
 */
interface ResultProps {

  /**
   * 出願人リスト
   * @type {object}
   * @memberof ResultProps
   */
  applicantsList: {

    /**
     * 識別番号
     * @type {string}
     */
    identificationNumber: string;

    /**
     * 氏名又は名称
     * @type {string}
     */
    name: string;

    /**
     * 住所又は居所
     * @type {string}
     */
    address: string;

    /**
     * 最終蓄積日
     * @type {string}
     */
    lastUpdatedAt: string;
    
  }[];

  /**
   * 前ページを戻る関数
   * @type {function}
   * @memberof ResultProps
   */
  previousPage: () => void;

  /**
   * 次ページを進む関数
   * @type {function}
   * @memberof ResultProps
   */
  nextPage: () => void;

  /**
   * 総数
   * @type {number}
   * @memberof ResultProps
   */
  totalCount: number;

  /**
   * ページ
   * @type {object}
   * @memberof ResultProps
   */
  pages: {
    page: string;
    prev: string;
    next: string;
  };

}

/**
 * 検索結果コンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Result: React.FC<ResultProps> = ({ applicantsList, previousPage, nextPage, totalCount, pages }) => {

  return (
    <>
      <h3>検索結果一覧</h3>
      <Table className={applicantsList.length === 0 ? 'd-none' : ''} striped hover>
        <thead>
          <tr>
            <th>識別番号</th>
            <th>氏名又は名称</th>
            <th>住所又は居所</th>
          </tr>
        </thead>
        <tbody>
          {
            applicantsList === undefined ?
            <tr>検索中です・・・</tr>
            :
            applicantsList.map((applicant, index) => (
              <tr key={index}>
                <td>
                  <Link to={`/applicant/${applicant.identificationNumber}`}>{applicant.identificationNumber}</Link>
                </td>
                <td>{applicant.name}</td>
                <td>{applicant.address}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <div className="row text-center">
        <Button variant='link' className="col-4 py-0 px-0" onClick={previousPage} disabled={pages.prev === '' ? true : false}>&lt;&lt;previous</Button>
        <div className="col-4">{(Number(pages.page) - 1)* 10 + 1}〜{(Number(pages.page) - 1)* 10 + applicantsList.length}件/{totalCount}件</div>
        <Button type="submit" variant='link' className="col-4 py-0 px-0" onClick={nextPage} disabled={pages.next === '' ? true : false}>next&gt;&gt;</Button>
      </div>
    </>
  );
}

export default Result;