import React from 'react';
import { Routes, Route } from 'react-router-dom';
import usePageTracking from './hooks/useTracking';
import './App.css';
import Home from './pages/Home';
import Detail from './pages/Detail';
import Download from './pages/Download';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import About from './pages/About';
import NotFound from './pages/NotFound';

/**
 * ヘッダーコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const App: React.FC = () => {

  usePageTracking();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/applicant/:id" element={<Detail />} />
      <Route path="/download" element={<Download />} />
      {/* <Route path="/news" element={<News />} /> */}
      <Route path="/faq" element={<Faq />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
