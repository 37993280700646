import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import Layout from '../../components/Layout';
import Logo from '../../assets/images/logo192.png';

/**
 * アバウトコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const NotFound: React.FC = () => {
  return (
    <HelmetProvider>
      <Head pageName='404' />
      <Layout>
        <div className="main-content">
          <div className="header py-7 py-lg-8" />
          <Container className="mt-5 pb-5">
            <Row className="justify-content-center">
              <Col lg="8" md="8">
                <Card className="shadow border-0">
                  <Card.Body className="px-lg-5 py-lg-5">
                    <h1 className="h3">
                      <img
                        src={Logo}
                        width="28"
                        height="28"
                        className='me-2'
                        alt="patent-applicant-search-logo"
                      />
                      404エラー
                    </h1>
                    <p>お探しのページは見つかりませんでした。</p>
                    <Link to='/'>トップ画面へ</Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </HelmetProvider>
  );
}

export default NotFound;