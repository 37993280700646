import React from 'react';
import { Table } from 'react-bootstrap';
import ChangeHistoryItem from '../ChangeHistoryItem';
import { ChangeHistoryApplicant } from '../../types';

/**
 * ChangeHistoriesコンポーネント用のPropsです。
 * @interface ChangeHistoriesProps
 */
interface ChangeHistoriesProps {

  /**
   * 最新の出願人情報
   * @type {object}
   * @memberof ChangeHistoriesProps
   */
  applicantsList: ChangeHistoryApplicant[];

}


/**
 * 最新情報コンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const ChangeHistories: React.FC<ChangeHistoriesProps> = ({ applicantsList }) => {

  return (
    <>
      <div className="mb-3 rounded-3 align-baseline" style={{backgroundColor: '#ffe4b5'}}>
        <h3 className="h5 py-2 px-2 fw-bold">変更履歴</h3>
      </div>
      <Table className={false ? 'd-none' : ''} striped hover>
        <thead>
          <tr>
            <th style={{width: '3%'}}>#</th>
            <th style={{width: '15%'}}>期間</th>
            <th style={{width: '10%'}}>公報情報</th>
            <th style={{width: '25%'}}>氏名又は名称</th>
            <th style={{width: '47%'}}>住所又は居所</th>
          </tr>
        </thead>
        <tbody>
          {
            applicantsList === undefined || applicantsList.length === 0 ?
            <tr>取得中・・・</tr>
            :
            applicantsList.map((applicant, index) => {
              return (
                <ChangeHistoryItem key={index} index={index} applicantInfo={applicant} />
              );
            })
          }
        </tbody>
      </Table>
    </>
  );
}

export default ChangeHistories;
