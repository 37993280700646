import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import { Card} from 'react-bootstrap';
import axios from 'axios';
import { convertKeysToCamelCase } from '../../common';
import Layout from '../../components/Layout';
import { FaQuestion } from 'react-icons/fa';
import { formatTimestamp } from '../../common';

/**
 * FAQコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Faq: React.FC = () => {

  const [faq, setFaq] = useState<
    {
      id: number;
      question: string;
      answer: string;
      createdAt: string;
      updatedAt: string;
    }[]
  >([]);

  (useEffect(() => {
    axios.get(`./cgi-bin/get_faq.py`).then((response) => {
      const data = convertKeysToCamelCase(response.data);
      setFaq(data.faq);
    });
  }, []));

  return (
    <HelmetProvider>
      <Head pageName='よくあるご質問' />
      <Layout>
        <h2 className="h4">
          <FaQuestion className='me-2 pb-2' />
          よくあるご質問
        </h2>
        <div>特許出願人検索.netについて、よくお問合せ頂くご質問への回答を掲載しています。</div>
        {
          faq.map((item, index) => {
            return (
              <Card key={index} className="my-4">
                <Card.Header>{`Q${index+1}. `}{item.question}</Card.Header>
                <Card.Body>
                  {/* <Card.Title>Card Title</Card.Title> */}
                  <Card.Text>
                    <div>{`A${index+1}. `}{item.answer}</div>
                    <div className="mt-1 text-end small text-secondary">更新日：{formatTimestamp(item.updatedAt)}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          })
        }
      </Layout>
    </HelmetProvider>
  );
}

export default Faq;