import * as React from 'react';
import { Helmet } from 'react-helmet-async';

// ページコンポーネントから、pageNameをpropしています
interface HeadProps {
  pageName: string;
}

// ページコンポーネントから、pageNameをpropしています
const Head: React.FC<HeadProps> = ({ pageName }) => {
  return (
    <Helmet>
      <title>特許出願人検索.net | {pageName}</title>
      <meta
          name="description"
          content="特許出願人検索.netは、特許庁の識別番号の情報を検索できるサービスです。"
      />
      <meta name="keywords" content="特許庁, 識別番号, 特許, 実用新案登録, 意匠登録, 商標登録, 出願人" />
      {/* <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@footlog_j" />
      <meta name="twitter:title" content="footlog" /> */}
      <meta name="twitter:description" content="特許出願人検索.netは、特許庁の識別番号の情報を検索できるサービスです。" />
      {/* <meta name="twitter:image" content="https://footlog.net/" /> */}
      <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"></meta>
    </Helmet>
  )
}

export default Head