import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Head from '../../components/Head';
import { Card } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import Layout from '../../components/Layout';
import Search from '../../components/Search';
import Result from '../../components/Result';
import NewItems from '../../components/NewItems';
import { FaInfo } from 'react-icons/fa';
import { convertKeysToCamelCase  } from '../../common';

/**
 * FormValue用のインターフェースです。
 * @interface FormValue
 */
interface FormValue {
  /**
   * 氏名又は名称
   * @type {string}
   * @memberof FormValue
   */
  name: string;

  /**
   * 識別番号
   * @type {string}
   * @memberof FormValue
   */
  identificationNumber: string;
}

/**
 * 出願人リスト
 * @interface Applicant
 */
interface Applicant {

  /**
   * 識別番号
   * @type {string}
   */
  identificationNumber: string;

  /**
   * 氏名又は名称
   * @type {string}
   */
  name: string;

  /**
   * 住所又は居所
   * @type {string}
   */
  address: string;

  /**
   * 最終蓄積日
   * @type {string}
   */
  lastUpdatedAt: string;

};

/**
 * Homeコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Home: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [formValue, setFormValue] = useState<FormValue>({
    name: searchParams.get('name') || '',
    identificationNumber: searchParams.get('identificationNumber') || ''
  });
  const [searchParameters, setSearchParameters] = useState<{name: string, identificationNumber: string, page: string}>({
    name: searchParams.get('name') || '',
    identificationNumber: searchParams.get('identificationNumber') || '',
    page: searchParams.get('page') || ''
  });
  const [applicantsList, setApplicantsList] = useState<Applicant[]>([]);
  const [pages, setPages] = useState<{page: string; prev: string; next: string}>({
    page: '',
    prev: '',
    next: ''
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResultMessage, setSearchResultMessage] = useState<string>('');

  (useEffect(() => {
    if(searchParameters.name !== '' || searchParameters.identificationNumber !== '') {
      setIsLoading(true);
      const params = new URLSearchParams(searchParams);
      params.set('name', searchParameters.name);
      params.set('identificationNumber', searchParameters.identificationNumber);
      params.set('page', searchParameters.page);
      setSearchParams(params.toString());
      axios.get(`./cgi-bin/search_applicants.py?name=${searchParameters.name}&identification_number=${searchParameters.identificationNumber}&page=${searchParameters.page}`).then((response) => {
        const data = convertKeysToCamelCase(response.data);
        setApplicantsList(data.applicants);
        setPages(data.pages);
        setTotalCount(data.totalCount);
        setSearchResultMessage(data.totalCount > 0 ? `${data.totalCount}件ヒットしました！` : `検索結果はありません。`);
        setIsLoading(false);
      });
    }
  }, [searchParameters]));

  const search = (): void => {
    setSearchParameters({
      name: formValue.name,
      identificationNumber: formValue.identificationNumber,
      page: '1'
    });
  };

  const previousPage = (): void => {
    setSearchParameters({
      name: searchParameters.name,
      identificationNumber: searchParameters.identificationNumber,
      page: pages.prev
    });
  }

  const nextPage = (): void => {
    setSearchParameters({
      name: searchParameters.name,
      identificationNumber: searchParameters.identificationNumber,
      page: pages.next
    });
  }

  return (
    <HelmetProvider>
      <Head pageName='トップ' />
      <Layout>
        <div className="my-5">
          <Search formValue={formValue} onChange={setFormValue} onSearch={search} />
          {
            isLoading ? 
              <div className="text-center my-3">
                <IconContext.Provider value={{color: 'gray', size: '2rem' }}>
                  <FaSpinner className="icon-spin" />
                </IconContext.Provider>
              </div>
            :
              searchResultMessage !== '' ?
                <div className='text-center mt-3'>{searchResultMessage}</div>
              :
                <></>
          }
        </div>
        {
          applicantsList.length !== 0 ?
            <div className="my-5">
              <Result applicantsList={applicantsList} previousPage={previousPage} nextPage={nextPage} totalCount={totalCount} pages={pages} />
            </div>
            :
            <></>
        }
        <hr />
        <div className="my-5">
          <Card>
            <Card.Header className="bg-danger text-white">
              <FaInfo className='me-2 pb-1' />
              お知らせ
            </Card.Header>
            <Card.Body className="small">
              {/* <Card.Text className="small"> */}
                <NewItems />
              {/* </Card.Text> */}
            </Card.Body>
          </Card>
        </div>
      </Layout>
    </HelmetProvider>
  );
}

export default Home;