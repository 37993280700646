const snakeToCamel = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

export const convertKeysToCamelCase = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  }

  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = snakeToCamel(key);
      newObj[camelKey] = convertKeysToCamelCase(obj[key]);
    }
  }

  return newObj;
}

export const toFullWidth = (str: string): string => {
  return str.replace(/[A-Za-z0-9]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
}

export const toHalfWidth = (str: string): string => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
    // 文字コードを取得し、差分を計算して半角に変換
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

export const formatIdentificationNumber = (input: string): string => {
  return toHalfWidth(input).replace(/[^0-9]/g, '').slice(0, 9).padStart(9, '0');
}

export const formatDateString = (input: string): string => {
  const date: string[] = input.split('-')
  return `${date[0]}年${date[1]}月${date[2]}日`
}

export const formatTimestamp = (input: string): string => {
  const splitInputDateAndTime = input.split(' ');
  const splitInputDate = splitInputDateAndTime[0].split('-');
  return `${splitInputDate[0]}年${splitInputDate[1]}月${splitInputDate[2]}日`;
}

export const formatApplicationNumber = (yonpoCode: string, applicationNumber: string): string => {
  let yonpo = '特';
  if(yonpoCode === '2') {
    yonpo = '実';
  } else if(yonpoCode === '3') {
    yonpo = '意';
  } else if(yonpoCode === '4') {
    yonpo = '商';
  }

  return `${yonpo}願${applicationNumber.slice(0, 4)}-${applicationNumber.slice(-6)}`;
}

export const formatRegisrationNumber = (yonpoCode: string, regisrationNumber: string): string => {
  let yonpo = '特許';
  if(yonpoCode === '2') {
    yonpo = '実用新案登録';
  } else if(yonpoCode === '3') {
    yonpo = '意匠登録';
  } else if(yonpoCode === '4') {
    yonpo = '商標登録';
  }

  return `${yonpo}第${regisrationNumber}`;
}