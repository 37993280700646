import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { convertKeysToCamelCase, formatTimestamp } from '../../common';

/**
 * 新着アイテムコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const NewItems: React.FC = () => {

  const [news, setNews] = useState<{
    id: number;
    item: string;
    createdAt: string;
    updatedAt: string;
  }[]>([]);

  (useEffect(() => {
    axios.get(`./cgi-bin/get_news.py`).then((response) => {
      const data = convertKeysToCamelCase(response.data);
      setNews(data.news);
    });
  }, []));

  return (
      <ul className="list-group list-group-flush notice">
        {
          news && news.length !== 0
          ?
            news.map((item) => {
              return (
                <li className="list-group-item">
                  <span className="me-3">{formatTimestamp(item.updatedAt)}</span>
                  <span>{item.item}</span>
                </li>
              )
            })
          :
            <div>お知らせがありません。</div>
        }
      </ul>
  );
}

export default NewItems;