import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { ChangeHistoryApplicant } from '../../types';
import { formatDateString, formatApplicationNumber, formatRegisrationNumber } from '../../common';

/**
 * ChangeHistoryItemコンポーネント用のPropsです。
 * @interface ChangeHistoryItemProps
 */
interface ChangeHistoryItemProps {

  /**
   * 索引
   * @type {number}
   * @memberof ChangeHistoryItemProps
   */
  index: number;

  /**
   * 最新の出願人情報
   * @type {ChangeHistoryApplicant}
   * @memberof ChangeHistoryItemProps
   */
  applicantInfo: ChangeHistoryApplicant;

}


/**
 * 最新情報コンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const ChangeHistoryItem: React.FC<ChangeHistoryItemProps> = ({ index, applicantInfo }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr>
      <td>{index+1}</td>
      <td>
        <div className="text-center">
          <div>{formatDateString(applicantInfo.effectiveStartDate)}</div>
          <div>〜</div>
          <div>{formatDateString(applicantInfo.effectiveEndDate)}</div>
        </div>
      </td>
      <td>
        <Button variant="link px-0 py-0" onClick={handleShow}>
          公報情報
        </Button>

        <Modal className="modal-xl" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>公報情報</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className={false ? 'd-none' : ''} hover>
              <thead>
                <tr>
                  <th>公報発行日</th>
                  <th>公報種別</th>
                  <th>出願日</th>
                  <th>出願番号</th>
                  <th>登録日</th>
                  <th>登録番号</th>
                </tr>
              </thead>
              <tbody>
                {
                  applicantInfo.gazetteDetails.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{formatDateString(item.dateOfPublication)}</td>
                        <td>{item.gazetteType}</td>
                        <td>{formatDateString(item.applicationDate)}</td>
                        <td>{formatApplicationNumber(item.yonpoCode, item.applicationNumber)}</td>
                        <td>{item.registrationDate ? formatDateString(item.registrationDate) : '-'}</td>
                        <td>{item.registrationNumber ? formatRegisrationNumber(item.yonpoCode, item.registrationNumber) : '-'}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </td>
      <td>
        <div className="my-1">{applicantInfo.name}</div>
        <div className="my-1">{applicantInfo.originalName}</div>
      </td>
      <td>
        <div className="my-1">{applicantInfo.address}</div>
        <div className="my-1">{applicantInfo.originalAddress}</div>
      </td>
    </tr>
  );
}

export default ChangeHistoryItem;
