import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FaSearch, FaInfo, FaQuestion, FaEnvelope, FaList, FaFileDownload } from "react-icons/fa"

/**
 * ヘッダーコンポーネントです。
 * @returns {JSX.Element} - JSX要素
 */
const Header: React.FC = () => {
  return (
    <header className="bg-light">
      <Navbar bg="dark" data-bs-theme="dark" expand="lg" className="py-1 d-md-none d-block">
        <Container>
          <Link to="/" className="navbar-brand text-decoration-none">特許出願人検索.net</Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link text-secondary app-link" to="/">
                  <FaSearch className='me-2 pb-1' />
                  トップ（検索）
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link text-secondary app-link" to="/news">
                  <FaInfo className='me-2 pb-1' />
                  お知らせ
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link text-secondary app-link" to="/download">
                  <FaFileDownload className='me-2 pb-1' />
                  CSVダウンロード
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-secondary app-link" to="/faq">
                  <FaQuestion className='me-2 pb-1' />
                  よくあるご質問
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-secondary app-link" to="/contact">
                  <FaEnvelope className='me-2 pb-1' />
                  お問い合わせ
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-secondary app-link" to="/about">
                  <FaList className='me-2 pb-1' />
                  当サービスについて
                </NavLink>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="border-bottom">
        <Container>
          <h1 className="h3 d-md-block d-none my-1">特許出願人検索.net</h1>
        </Container>
      </div>
      <div className="bg-dark d-md-block d-none">
        <ul className="nav nav-pills nav-fill container mt-0">
          <li className="nav-item">
            <Link className="nav-link text-white app-link" to="/">
              <FaSearch className='me-2 pb-1' />
              トップ（検索）
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link text-white app-link" to="/news">
              <FaInfo className='me-2 pb-1' />
              お知らせ</Link>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link text-white app-link" to="/download">
              <FaFileDownload className='me-2 pb-1' />
              CSVダウンロード</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white app-link" to="/faq">
              <FaQuestion className='me-2 pb-1' />
              よくあるご質問</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white app-link" to="/contact">
              <FaEnvelope className='me-2 pb-1' />
              お問い合わせ
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white app-link" to="/about">
              <FaList className='me-2 pb-1' />
              当サービスについて
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;